@import '../../../../scss/theme-bootstrap';

$sitewide-banner-height: 100px;

.content-block-sitewide-banner {
  overflow: hidden;
  height: 0;
  transition: all 0.1s ease;
  transform-origin: top;
  opacity: 1;
  transform: translateY(-100%);
  width: 100vw;
  position: absolute;
  #{$ldirection}: 50%;
  #{$rdirection}: 50%;
  margin-#{$ldirection}: -50vw;
  margin-#{$rdirection}: -50vw;
  max-width: none;
  top: 0;
  .content-block-sitewide-banner--enabled & {
    height: $sitewide-banner-height;
  }
  .content-block-sitewide-banner--hidden & {
    height: 0;
    opacity: 0;
    pointer-events: none;
  }
  &:hover {
    color: initial;
  }
  &__content-wrapper {
    height: 100%;
    z-index: 99;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    &.horizontal-align-#{$ldirection} {
      padding-#{$ldirection}: 15px;
    }
    &.horizontal-align-#{$rdirection} {
      padding-#{$rdirection}: 15px;
    }
    &.content-block {
      display: flex;
    }
  }
  &__content,
  &__content-signup {
    position: relative;
  }
  &__trigger {
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    #{$rdirection}: 30px;
    z-index: 1;
    cursor: pointer;
    -webkit-appearance: none;
    background: transparent;
    border-color: transparent;
    padding: 0;
    border-width: 1px;
    background: transparent;
    min-width: auto;
    stroke: $color-black;
    stroke-width: 50;
    width: auto;
    svg {
      display: block;
    }
    &[aria-expanded='false'] {
      display: none;
      & + .content-block-sitewide-banner__content-wrapper {
        display: none;
      }
    }
    &:hover {
      background: transparent;
    }
    .icon--caret--close {
      width: 15px;
      height: 15px;
    }
  }
  &__background-image {
    position: absolute;
    margin-#{$ldirection}: auto;
    margin-#{$rdirection}: auto;
    top: 0;
    bottom: 0;
    #{$ldirection}: 0;
    #{$rdirection}: 0;
    z-index: -1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  button.content-block__button,
  button.content-block__link {
    font-size: 13px;
    line-height: 1;
    padding: 0;
    background: none;
    border: none;
    text-transform: none;
    text-decoration: underline;
    color: inherit;
    text-align: inherit;
  }
  .content-block__line {
    margin: 0;
    padding: 5px 0;
  }
  .content-block--button {
    line-height: 1;
    font-size: 12px;
    text-transform: none;
  }
  p {
    padding: 0;
    margin: 0;
    line-height: 1;
  }
  .mantle-media-asset {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    img,
    picture,
    video {
      width: 100%;
      max-width: unset;
    }
  }
  &.content-block-sitewide-banner--light {
    color: $color-white;
    .content-block__line {
      color: $color-white;
    }
  }
}
// navigation interaction
.site-header {
  .content-block-sitewide-banner--enabled:not(.content-block-signup-overlay--enabled) & {
    transition: top 0.333s ease;
    top: $sitewide-banner-height;
    .toolbar & {
      margin-top: 87px + $sitewide-banner-height; // 87px height of toolbar
    }
  }
  .content-block-sitewide-banner--hidden & {
    top: 0;
  }
  .content-block-sitewide-banner {
    color: initial;
  }
  &:hover {
    .content-block-sitewide-banner {
      color: initial;
    }
  }
}
body.content-block-sitewide-banner--enabled:not(.content-block-sitewide-banner--hidden):not(.content-block-signup-overlay--enabled) {
  transition: margin-top 0.333s ease;
  margin-top: $sitewide-banner-height;
  &.toolbar {
    margin-top: 13px;
  }
  &.mobile-gnav-active {
    .site-header__section--nav {
      top: $header-height + $sitewide-banner-height;
      @include breakpoint($tablet-only) {
        top: 84px + $sitewide-banner-height;
      }
    }
  }
}
